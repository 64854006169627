<template>
  <div id="app">
    <div class="container-contact100">
      <div class="wrap-contact100">
        <form class="contact100-form validate-form">
          <img :src="require('images/smartview.jpg')" class="logo">
          <span class="contact100-form-title">{{ message }} </span>

          <!-- <div v-if="showNewCaseFile"> -->
            <div class="wrap-input100 validate-input" data-validate="Email is required">
              <span class="label-input100">Email</span>
              <input class="input100" type="text" name="email" placeholder="Enter Email" v-model="user.email">
              <!-- <span class="focus-input100"></span> -->
            </div>
            <div class="wrap-input100 validate-input" data-validate="Password is required">
              <span class="label-input100">Password</span>
              <input class="input100" type="password" name="password" placeholder="Password" v-model="user.password">
              <!-- <span class="focus-input100"></span> -->
            </div>

            <div class="container-contact100-form-btn">
              <div class="wrap-contact100-form-btn">
                <div class="contact100-form-bgbtn"></div>
                <button class="contact100-form-btn" @click.prevent="userLogin()">
                  <span>Submit <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
                </button>
              </div>

              <div class="mt-20 pointer" @click="openForgetPassword()">Forget Password?</div>
            </div>
          <!-- </div> -->
        </form>
      </div>
    </div>
  </div>

</template>

<script type="text/javascript">
// import axios from 'axios';

export default {
  data() {
    return {
      message: "Login",
      user: {
        email: "",
        password: "",
      },
    }
  },
  methods: {
    userLogin(){
      var _this = this
      var payload = {
        email: this.user.email,
        password: this.user.password,
      }
      
      this.$http.post('/api/authenticate_user', payload).then(response => {
        console.log('success', response);
        const token = localStorage.setItem("token", response.body.token)
        // alert('Successfully Logged In.')
        this.resetUser();
        this.$parent.openComponentName = 'AllCaseFiles'
      }, response => {
        console.log('fail');
        alert('Invalid Consultant Username/Password !!!');
      });
    },
    resetUser(){
      this.user.email = "";
      this.user.password = "";
    },
    openForgetPassword(){
      this.$parent.openComponentName = 'ForgetPassword'
    },
  }
}
</script>

<style lang='scss' scoped>
</style>