<template>
  <div id="app">
    <div class="">
      <div class="">
        <form class="contact100-form validate-form">
          <div class="contact100-form-title">
            <img :src="require('images/smartview.jpg')" class="logo">
            <i class="fa fa-arrow-left back_btn" @click.prevent="moveBack"></i>
            <span>{{ message }}</span>
            <span class="signout_btn">
              <i class="fa fa-sign-out" title="Exit" @click.prevent="signoutUser"></i>
            </span>
          </div>

          <div>
            <div class="wrap-input100 validate-input" data-validate="Participant Name is required">
              <span class="label-input100">Participant Name</span>
              <input class="input100" type="text" name="participant_name" placeholder="Enter Participant Name" v-model="edit_case_file.participant_name">
              <!-- <span class="focus-input100"></span> -->
            </div>
            <div class="wrap-input100 validate-input" data-validate="Location is required">
              <span class="label-input100">Location</span>
              <input class="input100" type="text" name="location" placeholder="Location" v-model="edit_case_file.location">
              <!-- <span class="focus-input100"></span> -->
            </div>

            <div class="container-contact100-form-btn">
              <div class="wrap-contact100-form-btn">
                <div class="contact100-form-bgbtn"></div>
                <button class="contact100-form-btn" @click.prevent="updateCaseFile()">
                  <span>Update <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script type="text/javascript">

export default {
  props: ['edit_case_file', 'case_files'],
  data() {
    return {
      message: "Edit Case File",
    }
  },
  mounted(){
    // debugger
  },
  methods: {
    updateCaseFile(){
      var _this = this;
      var id = this.edit_case_file.id
      var payload = { case_file: {
          participant_name: this.edit_case_file.participant_name,
          location: this.edit_case_file.location
        }
      }
      const token = localStorage.getItem("token")
      this.$http.put('/api/case_files/'+id, payload, {headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json'}}).then(response => {
        console.log('success');
        if(response.data.status === 1){
          alert('Updated successfully.');
          var index = _this.$parent.case_files.findIndex(o => o.id === id)
          if(typeof(index) != 'undefined'){
            this.$parent.case_files[index].participant_name = this.edit_case_file.participant_name;
            this.$parent.case_files[index].location = this.edit_case_file.location;
          }
          _this.moveBack();
        }else{
          alert('Something went wrong')
        }
      }, response => {
        console.log('fail');
      });
    },
    signoutUser(){
      var r = confirm("Do you want to Logout?");
      if (r == true) {
        localStorage.setItem('token', '');
        this.$parent.openComponentName = 'LoginCaseFile'
      } else {
        console.log("You pressed Cancel!");
      }
      
    },
    moveBack(){
      this.$parent.is_edit = false
      this.$parent.edit_case_file.participant_name = '';
      this.$parent.edit_case_file.location = '';
      this.$parent.edit_case_file.id = '';
    },
  }
}
</script>

<style lang='scss' scoped>
</style>