<template>
  <div id="app">
    <div class="container-contact100">
      <div class="wrap-contact100">

        <div class="contact100-form-title">
          <img :src="require('images/smartview.jpg')" class="logo">
          <i class="fa fa-arrow-left back_btn" @click.prevent="moveBack"></i>
          <span>{{ message }}</span>
          <span class="signout_btn">
            <i class="fa fa-plus mr-10" title="Add New" @click.prevent="addMediaFile"></i>
            <i class="fa fa-sign-out" title="Exit" @click.prevent="signoutUser"></i>
          </span>
          <div class="mt-5 case_name">{{ case_file.participant_name }} - {{ case_file.location }}</div>
        </div>

        <div v-if="uploadMedia">
          <div class="wrap-input100">
            <span class="label-input100">Image</span>
            <input class="input100" type="file" accept="image/*" @change="onFileChange($event, 'image')" multiple>
          </div>

          <div class="wrap-input100">
            <span class="label-input100">Video</span>
            <input class="input100" type="file" accept="video/*" @change="onFileChange($event, 'video')" multiple>
          </div>
        </div>  

        <div v-if="listMedia">
          <div class="container-contact100-form-btn pb-40">
            <div class="wrap-contact100-form-btn">
              <div class="contact100-form-bgbtn"></div>
              <button class="contact100-form-btn" @click.prevent="saveMedia()">
                <span class="mr-5">Site Visit Complete</span>
                <span class="loader" v-if="loader"></span>
                <span v-else><i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
              </button>
            </div>
          </div>

          <div v-for="(media, index) in media_files" :key="index">
            <div class="case_file_media">
              <video :src="media.link" controls v-if="media.file_type=='video'" />
              <img :src="media.link" v-else />
            </div>
            
            <div class="wrap-input100 validate-input" data-validate="Description is required">
              <div class="inline-block w-full">
                <span class="label-input100"><b>Description</b></span>
                <i class="fa fa-trash delete_btn" @click.prevent="deleteMedia(media.id, index)"></i>
              </div>
              <textarea class="input100" name="description" placeholder="Your description here..." @change="setDescription(media, index, $event)" :value="media.description"></textarea>
              <!-- <span class="focus-input100"></span> -->
            </div> 
          </div>

          <!-- <div class="container-contact100-form-btn">
            <div class="wrap-contact100-form-btn">
              <div class="contact100-form-bgbtn"></div>
              <button class="contact100-form-btn" @click.prevent="saveMedia()">
                <span>Submit <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>

props: ['case_file_id']

export default {
  data() {
    return {
      message: "Media Files",
      case_file: {
        id: null,
        participant_name: null,
        location: null,
      },
      uploadMedia: false,
      inputPicture: null,
      listMedia: true,
      media_files: [],
      delete_media_files: [],
      loader: false,
    }
  },
  // computed: {
  //   reverseMediaFiles(){
  //     return this.media_files.slice().reverse();
  //   }
  // },
  beforeMount(){
    const token = localStorage.getItem("token")
    var _this = this;
    var id = this.$attrs.case_file_id;
    if(id){
      this.$http.get('/api/case_files/'+id, {headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json'}}).then(response => {
          console.log('success');
          _this.case_file.id = response.data.data.id;
          _this.case_file.participant_name = response.data.data.participant_name;
          _this.case_file.location = response.data.data.location;
          _this.media_files = response.data.media_files;
        }, response => {
          console.log('fail');
        });
    }
  },
  methods: {
    onFileChange: function(e, media_type) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      for(var i=0; i < files.length; i++){
        this.createThumb(files[i], media_type);
      }
      this.listMedia = true
      this.uploadMedia = false
    },
    createThumb(file, media_type) {
      var image = new Image();
      var reader = new FileReader();

      reader.onload = (e) => {
        this.media_files.unshift({ file: file, file_type: media_type, link: e.target.result, id: '' })
      };
      reader.readAsDataURL(file);
    },
    setDescription(media, index, e){
      media["description"] = e.target.value
    },
    saveMedia(){
      var _this = this
      const token = localStorage.getItem("token")
      var id = this.case_file.id;
      var formData = new FormData()
      this.loader = true

      formData.append('delete_media_files', this.delete_media_files);

      for(var i=0;i<this.media_files.length;i++){
        var media = this.media_files[i];
        formData.append('media_files['+i+'][id]'         , media.id);
        formData.append('media_files['+i+'][link]'       , (media.id ? media.link : media.file));
        formData.append('media_files['+i+'][description]', media.description);
        formData.append('media_files['+i+'][file_type]'  , media.file_type);
      }

      this.$http.post('/api/case_files/'+id+'/store_media', formData, 
        {headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'multipart/form-data'}})
      .then(response => {
        console.log('success');
        alert('Submitted successfully.')
        _this.$parent.openComponentName = 'AllCaseFiles';
        _this.loader = false
      }, response => {
        console.log('fail');
        _this.loader = false
      });
    },
    // resetCaseFile(){
    //   this.message = "New Case File";
    //   this.case_file.participant_name = "";
    //   this.case_file.location = "";
    //   // this.showNewCaseFile = true;
    //   this.uploadMedia = false;
    //   this.inputPicture = null;
    //   this.listMedia = false;
    //   this.media_files = [];
    // },
    addMediaFile(){
      // this.showNewCaseFile = false;
      this.uploadMedia = true;
      this.listMedia = false
    },
    signoutUser(){
      var r = confirm("Do you want to Logout?");
      if (r == true) {
        localStorage.setItem('token', '');
        this.$parent.openComponentName = 'LoginCaseFile'
      } else {
        console.log("You pressed Cancel!");
      }
      
    },
    moveBack(){
      if(this.uploadMedia){
        this.uploadMedia = false;
        this.listMedia = true;
      }else{
        this.$parent.openComponentName = 'AllCaseFiles'
        this.$parent.id = null;
      }
    },
    deleteMedia(media_id, index){
      if(media_id){
        this.delete_media_files.push(media_id);
      }
      this.media_files.splice(index, 1);
    }
  }
}
</script>

<style lang='scss' scoped>
</style>