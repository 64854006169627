<template>
  <div id="app">
    <div class="container-contact100">
      <div class="wrap-contact100">
        <EditCaseFile  :edit_case_file="edit_case_file" v-if="is_edit" />
        <div v-else>
          <div class="contact100-form-title">
            <img :src="require('images/smartview.jpg')" class="logo">
            <span>{{ message }}</span>
            <span class="signout_btn">
              <i class="fa fa-plus mr-10" title="Add New" @click.prevent="addCaseFile"></i>
              <i class="fa fa-sign-out" title="Exit" @click.prevent="signoutUser"></i>
            </span>
          </div>
          <div v-for="(c, index) in case_files" :key="index" class="inline-block w-full">
            <div class="inline-block pointer w-3-4 px-0 py-5" @click="openMediaFiles(c.id)">
              <div class="px-0 py-5">{{ c.participant_name }}</div>
              <div class="px-0 py-5">{{ c.location }}</div>
              <!-- <div class="px-0 py-5">{{ get_datetime(c.created_at) }}</div> -->
            </div>
            <div class="inline-block pull-right w-1-5 px-0 py-20">
              <i class="fa fa-trash delete_btn" title="Delete" @click.prevent="deleteCaseFile(c.id, index)"></i>
              <i class="fa fa-edit mr-10 edit_btn" title="Edit" @click.prevent="editCaseFile(c, index)"></i>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import EditCaseFile from './edit.vue'
export default {
  components: {
    EditCaseFile
  },
  data() {
    return {
      message: "All Case Files",
      case_files: [],
      is_edit: false,
      edit_case_file: {
        participant_name: '',
        location: '',
        id: '',
      }
    }
  },
  mounted(){
    const token = localStorage.getItem("token")
    var _this = this;
    this.$http.get('/api/case_files', {headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json'}}).then(response => {
        console.log('success');
        _this.case_files = response.data.data
      }, response => {
        console.log('fail');
      });
  },
  methods: {
    signoutUser(){
      var r = confirm("Do you want to Logout?");
      if (r == true) {
        localStorage.setItem('token', '');
        this.$parent.openComponentName = 'LoginCaseFile'
      } else {
        console.log("You pressed Cancel!");
      }
      
    },
    addCaseFile(){
      this.$parent.openComponentName = 'NewCaseFile'
    },
    deleteCaseFile(id, index){
      const token = localStorage.getItem("token")
      var _this = this
      this.$http.delete('/api/case_files/'+id, {headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json'}}).then(response => {
        console.log('success');
        if(response.data.status === 1){
          _this.case_files.splice(index, 1)
        }
      }, response => {
        console.log('fail');
      });
    },
    editCaseFile(obj){
      this.is_edit = true;
      this.edit_case_file.participant_name = obj.participant_name;
      this.edit_case_file.location = obj.location,
      this.edit_case_file.id = obj.id
    },
    openMediaFiles(id){
      this.$parent.openComponentName = 'Media';
      this.$parent.case_file_id = id;
    },
    get_datetime(c){
      var d = new Date(c)
      var date = d.getDate()
      var month = d.getMonth()
      var year = d.getFullYear()
      var hour = d.getHours()
      var min = d.getMinutes()
      return `${date}/${month}/${year} ${hour}:${min}`
    },
  }
}
</script>

<style lang='scss' scoped>
</style>
