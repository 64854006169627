<template>
  <div id="app">
    <div class="container-contact100">
      <div class="wrap-contact100">
        <form class="contact100-form validate-form">
          <div class="contact100-form-title">
            <img :src="require('images/smartview.jpg')" class="logo">
            <i class="fa fa-arrow-left pull-left" @click.prevent="moveBack"></i>
            <span>{{ message }}</span>
            <span class="signout_btn">
              <i class="fa fa-sign-out" title="Exit" @click.prevent="signoutUser"></i>
            </span>
          </div>

          <div class="wrap-input100 validate-input" data-validate="Participant Name is required">
            <span class="label-input100">Participant Name</span>
            <input class="input100" type="text" name="participant_name" placeholder="Enter Participant Name" v-model="case_file.participant_name" required>
            <!-- <span class="focus-input100"></span> -->
          </div>
          <div class="wrap-input100 validate-input" data-validate="Location is required">
            <span class="label-input100">Location</span>
            <input class="input100" type="text" name="location" placeholder="Location" v-model="case_file.location" required>
            <!-- <span class="focus-input100"></span> -->
          </div>

          <div class="container-contact100-form-btn">
            <div class="wrap-contact100-form-btn">
              <div class="contact100-form-bgbtn"></div>
              <button class="contact100-form-btn" @click.prevent="createCaseFile()">
                <span>Submit <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

</template>

<script type="text/javascript">

export default {
  data() {
    return {
      message: "New Case File",
      case_file: {
        participant_name: "",
        location: "",
      },
    }
  },
  methods: {
    createCaseFile(){
      var _this = this;
      var participant_name = this.case_file.participant_name
      var location = this.case_file.location
      if(participant_name == '' || location == ''){
        alert('Enter all values');
      }else{
        var _this = this;
        const token = localStorage.getItem("token");
        var payload = {case_file: this.case_file};
        this.$http.post('/api/case_files/', payload, {
          headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json'}})
        .then(response => {
          console.log('success');
          if(response.data.status === 1){
            alert('Created successfully.');

            this.$parent.openComponentName = 'Media';
            this.$parent.case_file_id = response.data.data;
          }else{
            alert('Something went wrong')
          }
        }, response => {
          console.log('fail');
        });
      }
    },
    signoutUser(){
      var r = confirm("Do you want to Logout?");
      if (r == true) {
        localStorage.setItem('token', '');
        this.$parent.openComponentName = 'LoginCaseFile'
      } else {
        console.log("You pressed Cancel!");
      }
      
    },
    moveBack(){
      this.$parent.openComponentName = 'AllCaseFiles'
    },
  }
}
</script>

<style lang='scss' scoped>
</style>
