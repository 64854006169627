<template>
  <div id="app">
    <component :is="openComponentName" :case_file_id="case_file_id"> </component>
  </div>

</template>

<script>
import LoginCaseFile from '../case_files/login.vue'
import NewCaseFile from '../case_files/new.vue'
import AllCaseFiles from '../case_files/index.vue'
import Media from '../case_files/media.vue'
import ForgetPassword from '../case_files/forget_password.vue'

export default {
  components: {
    LoginCaseFile,
    NewCaseFile,
    AllCaseFiles,
    Media,
    ForgetPassword,
  },
  data() {
    return {
      openComponentName: null,
      case_file_id: null,
    }
  },

  mounted(){
    const token = localStorage.getItem("token")
    if(token){
      this.openComponentName = 'AllCaseFiles'
    }else{
      this.openComponentName = 'LoginCaseFile'
    }
  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
</style>
