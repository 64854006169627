import Vue from 'vue/dist/vue.esm'
import CaseFilesNew from '../case_files/new.vue'
import CaseFilesLogin from '../case_files/login.vue'
import CaseFilesIndex from '../case_files/index.vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)
Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

import HomeIndex from '../home/index.vue'

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById('vue');
  if(element != null){
    const app = new Vue({
      el: element,
      components: { HomeIndex }
    })
  }
})
