<template>
  <div id="app">
    <div class="container-contact100">
      <div class="wrap-contact100">
        <div class="contact100-form">
          <div class="contact100-form-title">
            <img :src="require('images/smartview.jpg')" class="logo">
            <i class="fa fa-arrow-left pull-left" @click.prevent="moveBack"></i>
            <span>{{ message }}</span>
          </div>

          <!-- Display Recovery Email Form -->
          <div v-if='isPasswordRecoveryEmail'>
            <div class="wrap-input100 validate-input">
              <span class="label-input100">Email</span>
              <input class="input100" type="text" name="email" placeholder="Enter Email" v-model="email">
            </div>

            <div class="container-contact100-form-btn">
              <div class="wrap-contact100-form-btn">
                <div class="contact100-form-bgbtn"></div>
                <button class="contact100-form-btn" @click.prevent="sendPasswordInstructions()">
                  <span>Submit <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
                </button>
              </div>
            </div>
          </div>

          <!-- Display Recovery Code Form -->
          <div v-if='isPasswordRecoveryCode'>
            <div class="wrap-input100 validate-input">
              <span class="label-input100">Recovery Code:</span>
              <input class="input100" type="text" placeholder="Enter Recovery Code" v-model="code">
            </div>          

            <div class="container-contact100-form-btn">
              <div class="wrap-contact100-form-btn">
                <div class="contact100-form-bgbtn"></div>
                <button class="contact100-form-btn" @click.prevent="confirmRecoveryCode()">
                  <span>Submit <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
                </button>
              </div>
            </div>
          </div>

          <!-- Display Recovery Password Form -->
          <div v-if='isPasswordRecoveryPassword'>
            <div class="wrap-input100 validate-input">
              <span class="label-input100">New Password:</span>
              <input class="input100" type="text" placeholder="Enter New Password" v-model="new_password">
            </div>          

            <div class="container-contact100-form-btn">
              <div class="wrap-contact100-form-btn">
                <div class="contact100-form-bgbtn"></div>
                <button class="contact100-form-btn" @click.prevent="resetPassword()">
                  <span>Submit <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script type="text/javascript">

export default {
  data() {
    return {
      message: "Forget Password",
      email: null,
      isPasswordRecoveryEmail: true,
      isPasswordRecoveryCode: false,
      isPasswordRecoveryPassword: false,
      code: null,
      new_password: null,
      user_hashid: null,
    }
  },
  methods: {
    moveBack(){
      this.$parent.openComponentName = 'LoginCaseFile';
    },
    sendPasswordInstructions(){
      if(this.email === null){
        alert('Enter Email address !!!');
        return
      }
      const token = localStorage.getItem("token");
      var payload = {
        email: this.email
      }
      this.$http.post('/api/send_reset_password_instructions', payload, 
        { headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json'}})
        .then(response => {
        if(response.data.status == 1){
          alert('Sent Password recovery Code at your email')
          this.isPasswordRecoveryEmail = false;
          this.isPasswordRecoveryCode = true;
          this.isPasswordRecoveryPassword = false;
        }
      }, response => {
        console.log('fail');
        alert('User Not Found !!!');
      });
    },
    confirmRecoveryCode(){
      if(this.code === null){
        alert('Enter Recovery Code !!!')
        return
      }
      const token = localStorage.getItem("token");
      var payload = {
        email: this.email,
        code: this.code,
      }
      this.$http.post('/api/confirm_recovery_code', payload, 
        { headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json'}})
        .then(response => {
        if(response.data.status == 1){
          this.user_hashid = response.data.user_hashid;
          this.isPasswordRecoveryEmail = false;
          this.isPasswordRecoveryCode = false;
          this.isPasswordRecoveryPassword = true;
        }else{
          alert('Invalid Code !!!')
        }
      }, response => {
        console.log('fail');
      });
    },
    resetPassword(){
      if(this.password === null){
        alert('Enter New Password !!!');
        return
      }
      const token = localStorage.getItem("token");
      var payload = {
        email: this.email,
        code: this.code,
        password: this.new_password,
        user_hashid: this.user_hashid,
      }
      this.$http.post('/api/reset_password', payload, 
        { headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json'}})
        .then(response => {
        if(response.data.status == 1){
          alert('Password Changed.')
          this.$parent.openComponentName = 'LoginCaseFile';
        }else{
          alert('Something went wrong !!!') 
        }
      }, response => {
        console.log('fail');
      });
    },

  }
}
</script>

<style lang='scss' scoped>
</style>